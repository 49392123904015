import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { extendStyles, formatCurrency, getTaxAmountById } from 'helpers';

import { useData } from '../../hooks';

import styles from './styles.scss';

extendStyles(styles);

const GrandTotal = ({ appointments, expenses, team }) => {
  const { options } = useData();

  const gstTotal = useMemo(() => {
    const expGst = (expenses || []).reduce((res, cur) => res + (cur.gst || 0), 0);

    const teamTax = getTaxAmountById({ data: options, field: 'taxes', id: team.purchaseTax });
    const apptSubTotal = Object.values(appointments || {}).reduce((res, cur) => res + cur.total, 0);
    const apptGst = teamTax ? (apptSubTotal * teamTax) / 100 : 0;

    return expGst + apptGst;
  }, [team, expenses, appointments, options]);

  const pstTotal = useMemo(() => (expenses || []).reduce((res, cur) => res + (cur.pst || 0), 0), [expenses]);

  const grandTotal = useMemo(() => {
    const expTotal = (expenses || []).reduce((res, cur) => res + cur.amount, 0);

    const teamTax = getTaxAmountById({ data: options, field: 'taxes', id: team.purchaseTax });
    const apptSubTotal = Object.values(appointments || {}).reduce((res, cur) => res + cur.total, 0);
    const apptTax = teamTax ? (apptSubTotal * teamTax) / 100 : 0;
    const apptTotal = apptSubTotal + apptTax;

    return expTotal + apptTotal;
  }, [team, expenses, appointments, options]);

  return (
    <div className={ styles.container }>
      <div className={ styles.title }>Grand Total of All Costs</div>
      <div className={ styles.total }>
        <div className={ styles.label }>GST:</div>
        <div className={ styles.amount }>{formatCurrency(gstTotal)}</div>
      </div>
      <div className={ styles.total }>
        <div className={ styles.label }>PST:</div>
        <div className={ styles.amount }>{formatCurrency(pstTotal)}</div>
      </div>
      <div className={ styles.total }>
        <div className={ styles.label }>Grand Total:</div>
        <div className={ styles.amount }>{formatCurrency(grandTotal)}</div>
      </div>
    </div>
  );
};

GrandTotal.propTypes = {
  team: PropTypes.shape({
    purchaseTax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  expenses: PropTypes.arrayOf(PropTypes.shape({})),
  appointments: PropTypes.shape({}),
};

GrandTotal.defaultProps = {
  expenses: null,
  appointments: null,
};

export default GrandTotal;
